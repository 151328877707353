import React, { useState, useEffect, useCallback } from 'react';
import './Comparison.css';
import { searchProduct } from '../../services/Product.service';
import { toastError } from '../../utils/toastutill';
import { generateImageUrl } from '../../services/url.service';
import { Link } from 'react-router-dom';

// Simulated API function (replace with real API call)
const fetchsFromAPI = async (searchTerm = '') => {
  // Example API endpoint: Replace with actual API URL and key
 try {

      const { data: res } = await searchProduct(
        `name=${searchTerm}`
      );
      if (res) {
        return res.data
      } else {
        return []
      }

    } catch (error) {
      toastError(error);
    }
};

const Comparison = () => {
  const [selecteds, setSelecteds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [availables, setAvailables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  // Handle search


  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };


  const handleSearch = useCallback(
    debounce(async (term) => {
      if (term.trim() !=='') {
        setIsLoading(true);
        const filteredCars = await fetchsFromAPI(term);
        setAvailables(filteredCars.filter(
          product => !selecteds.some(selected => selected._id === product._id)
        ));
        setIsLoading(false);
      }
    }, 300), // 300ms delay
    [selecteds]
  );
  
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  // Handle adding a product
  const handleAdd = (productId) => {
    if (selecteds.length < 3) {
      const productToAdd = availables.find(product => product._id === productId);
      if (productToAdd) {
        setSelecteds([...selecteds, productToAdd]);
        setAvailables(availables.filter(product => product._id !== productId));
        setSearchTerm('');
      }
    } else {
      alert('Maximum 3 products can be compared at once.');
    }
  };

  // Handle removing a product
  const handleRemove = (productId) => {
    const productToRemove = selecteds.find(product => product._id === productId);
    setSelecteds(selecteds.filter(product => product._id !== productId));
    setAvailables([...availables, productToRemove]);
  };
  return (

    <div className="container-fluid subscription-container subscription-card-container">
      <div className="container">
        <div className="subsctiption-heading">Compare Products</div>
        {/* <div className="subscription-description">
          Buy our subscription to get a steady flow of leads for your business
          and take your business to the next level.
        </div> */}
        <div className="comparison-container">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search for a product to add..."
          value={searchTerm}
          onChange={handleInputChange}
        />
        {/* <button onClick={handleSearch}>Search</button> */}
        {isLoading && <span className="loading">Loading...</span>}
        {searchTerm && !isLoading && availables.length > 0 && (
          <ul className="suggestions">
            {availables.map((product) => (
              <li
                key={product._id}
                onClick={() => handleAdd(product._id)}
                className="suggestion-item"
              >
                {product.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/*  Comparison Grid */}
      <div className="product-grid">
        {selecteds.map((product) => (
          <div key={product._id} className="product-column">
            <button
              className="remove-btn"
              onClick={() => handleRemove(product._id)}
            >
              ×
            </button>
            <img
              src={generateImageUrl(product.mainImage)}
              alt={product.name}
              className="product-image"
            />
            <h2> <Link to={`/ShopDetail/${product?.slug}`}>{product.name}</Link></h2>
            <table className="spec-table">
              <tbody>
           
                <tr>
                  <td>Price</td>
                  <td>Rs {product.price}</td>
                </tr>
                <tr>
                  <td>Rating</td>
                  <td>{product.productrating}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{product.cityName} , {product.stateName}</td>
                </tr>
                {product?.categoryName && (
                   <tr>
                   <td>Category</td>
                   <td>{product.categoryName}</td>
                 </tr>
                )}
               
                <tr>
                  <td>Company </td>
                  <td>{product.companyName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
      </div>

      
    </div>
   
  );
};

export default Comparison;