export const reactCustomStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "#bf9f65", // Change placeholder color
      fontSize: ".9rem",
      fontWeight: "500",
      fontFamily: "Poppins, sans serif",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      borderColor: "#ccc",
      height: 50,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007aff" : "white",
      color: state.isSelected ? "white" : "black",
      padding: 10,
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexWrap: "nowrap", // Prevents wrapping
      overflowX: "auto", // Allows horizontal scrolling if values exceed width
      whiteSpace: "nowrap",
    }),
  };
