import moment from "moment";
import React, { useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddAdvertisement, getAdvertisementById, updateAdvertisementApi } from "../../services/Advertisement.service";
import { getAllProductsBySupplierId } from "../../services/Product.service";
import { getUserById } from "../../services/User.service";
import { generateImageUrl } from "../../services/url.service";
import FileUpload from "../Utility/FileUpload";
import { errorToast, successToast } from "../Utility/Toast";

// Zod schema definition
const promotionSchema = z.object({
  productId: z.string().min(1, "Please select a product"),
  message: z.string().min(1, "Promotion message is required"),
  image: z.string().min(1, "Promotion image/video is required"),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  isVideo: z.boolean().default(false),
});

export default function AddPromotion() {
  const navigate = useNavigate();
  const userObj = useSelector(state => state.auth.user);
  const [userObjData, setUserObjData] = React.useState({});
  const [searchParams] = useSearchParams();
  const [isEditingModeOn, setIsEditingModeOn] = React.useState(false);
  const [productArr, setProductArr] = React.useState([]);
  const [productSlug, setProductSlug] = React.useState("");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(promotionSchema),
    defaultValues: {
      productId: "",
      message: "",
      image: "",
      startDate: moment().format("YYYY-MM-DDThh:mm:ss"),
      endDate: moment().format("YYYY-MM-DDThh:mm:ss"),
      isVideo: false,
    },
  });

  // Watch the image field value
  const image = useWatch({
    control,
    name: "image",
  });

  useEffect(() => {
    if (image && image.includes("base64")) {
      setValue("isVideo", image.slice(0, 30).toLowerCase().includes("video"));
    }
  }, [image, setValue]);

  const onSubmit = async (data) => {
    try {
      const obj = {
        userId: userObj._id,
        productId: data.productId,
        message: data.message,
        image: data.image,
        productSlug,
        endDate: data.endDate,
        startDate: data.startDate,
        isVideo: data.isVideo,
      };

      let res;
      if (isEditingModeOn) {
        res = await updateAdvertisementApi(obj, searchParams.get("id"));
      } else {
        res = await AddAdvertisement(obj);
      }

      if (res.data.message) {
        successToast(res.data.message);
        navigate("/View/My-Promotions");
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetProducts = async () => {
    try {
      let { data: res } = await getAllProductsBySupplierId(userObj?._id);
      if (res.data) {
        setProductArr(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetSaleById = async () => {
    try {
      let { data: res } = await getAdvertisementById(searchParams.get("id"));
      if (res.data) {
        reset({
          productId: res.data.productId,
          message: res.data.message,
          image: res.data.image,
          startDate: moment(res.data.startDate).format("YYYY-MM-DDThh:mm:ss"),
          endDate: moment(res.data.endDate).format("YYYY-MM-DDThh:mm:ss"),
          isVideo: res.data.isVideo,
        });
        setProductSlug(res.data.productSlug);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetUser = async () => {
    try {
      let { data: res } = await getUserById(userObj._id);
      if (res.data) {
        setUserObjData(res.data);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  useEffect(() => {
    handleGetUser();
    handleGetProducts();
    if (searchParams.get("id")) {
      setIsEditingModeOn(true);
      handleGetSaleById();
    }
  }, []);

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-md-12">
          <div className="right frormcontainer">
            <h3 className="heading formheading ps-3">
              {isEditingModeOn ? "Edit" : "Add"} Promotions
            </h3>
            <form className="form profile-section-container" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Product <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="productId"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="form-control"
                        onChange={(e) => {
                          field.onChange(e);
                          const tempObj = productArr.find(el => el._id === e.target.value);
                          setProductSlug(tempObj?.slug || "");
                        }}
                      >
                        <option value="">Please Select Product</option>
                        {productArr.map(el => (
                          <option key={el._id} value={el._id}>
                            {el.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.productId && (
                    <span className="text-danger">{errors.productId.message}</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label>
                    Start Date <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="datetime-local"
                        className="form-control"
                      />
                    )}
                  />
                  {errors.startDate && (
                    <span className="text-danger">{errors.startDate.message}</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label>
                    End Date <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="datetime-local"
                        className="form-control"
                      />
                    )}
                  />
                  {errors.endDate && (
                    <span className="text-danger">{errors.endDate.message}</span>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label>
                    Promotion Image/Video <span className="text-danger">*</span>{" "}
                    <span>width:100px and height:100px</span>
                  </label>
                  <div className="my-3">
                    <Controller
                      name="image"
                      control={control}
                      render={({ field }) => (
                        <>
                          {field.value && (
                            field.value.includes("base64") && field.value.slice(0, 30).toLowerCase().includes("video") ? (
                              <video src={field.value} height={100} width={100} />
                            ) : (
                              <img
                                style={{ height: 100, width: 100 }}
                                src={field.value.includes("base64") ? field.value : generateImageUrl(field.value)}
                                alt=""
                              />
                            )
                          )}
                          <FileUpload
                            acceptedType={"image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*"}
                            onFileChange={(value) => setValue("image", value)}
                          />
                        </>
                      )}
                    />
                  </div>
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>

                <div className="col-md-12">
                  <label>
                    Promotion Message <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className="form-control"
                        placeholder="Enter Message here"
                      />
                    )}
                  />
                  {errors.message && (
                    <span className="text-danger">{errors.message.message}</span>
                  )}
                </div>

                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-custom btn-yellow mt-2 px-4 fs-5 me-5"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}