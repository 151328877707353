import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { ROLES } from "../../utils/Roles.utils";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Form, Row, Col } from "react-bootstrap";
import { BsX } from "react-icons/bs"; // Import close icon
import logo2 from "../../assets/image/home/image 110.png";

import {
  FaInstagram,
  FaFacebookF,
  FaYoutube,
  FaHome,
  FaFilter,
} from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
// import logo from "../../assets/image/home/image 109.png";
import logo from "../../assets/image/home/images/logo6.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiFillHome, AiOutlineLogout, AiTwotoneSetting } from "react-icons/ai";
import { BiBell, BiChevronDown, BiSearch } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { GrProductHunt } from "react-icons/gr";
import {
  MdLocalOffer,
  MdOutlineSubscriptions,
  MdSportsEsports,
  MdSubscriptions,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavLink,
  redirect,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  login,
  logoutUser,
  otpSend,
} from "../../redux/features/auth/authSlice";
import {
  getNestedCategories,
  useCategories,
} from "../../services/Category.service";
import {
  getUserNotifications,
  searchVendorFromDb,
  sentOtp,
  getUnreadNotificationsCount,
} from "../../services/User.service";
import { getDecodedToken, getToken } from "../../services/auth.service";
import { toastError, toastSuccess } from "../../utils/toastutill";
import { images } from "../Utility/Images";
import { errorToast, successToast } from "../Utility/Toast";
import { FaBell } from "react-icons/fa";
import "../../assets/css/Header.css";
import whp from "../../assets/images/whatsup1.png";
import fb from "../../assets/images/facebook.png";
import inst from "../../assets/images/instagram.png";
import yout from "../../assets/images/youtube.png";
import { LuBellRing } from "react-icons/lu";
import Mybanner from "../User-Components/Mybanner";
import { SignModalContext } from "../../context/SignModalContext";

// import Container from "react-bootstrap/Container";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Nav from "react-bootstrap/Nav";

function Header() {
  const dispatch = useDispatch();
  const { isModalOpen, closeSignModal, openSignModal } =
    useContext(SignModalContext);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const userObj = useSelector((state) => state.auth.user);
  const forchecking_type = useSelector((state) => state.auth.role);
  const tempLoginObj = useSelector((state) => state.auth.tempLoginObj);
  let role = useSelector((state) => state.auth.role);
  const ref = useRef();
  const formRef = useRef(null);
  const location = useLocation();
  const [showcatgoryNav, setShowcatgoryNav] = useState(true);
  const [searchBy, setSearchBy] = useState(false);
  const [searchType, setSearchType] = useState("vendor");
  const [searchList, setSearchList] = useState([
    { name: "Search By keyword", checked: true, type: "vendor" },
    { name: "Search By Product", checked: false, type: "product" },
  ]);

  const [searchText, setSearchText] = useState("");
  const [signInList, setSignInList] = useState([
    {
      name: "Profile",
      icon: <AiFillHome />,
      link: "/My-Profile",
    },
    {
      name: "Buy Subscription",
      icon: <MdSubscriptions />,
      link: "/Subscription",
    },
    {
      name: "Buy Topups",
      icon: <MdSubscriptions />,
      link: "/Topup",
    },
    {
      name: "My Topups",
      icon: <MdSubscriptions />,
      link: "/MyTopup",
    },

    {
      name: "My Subscription",
      icon: <MdOutlineSubscriptions />,
      link: "/MySubscriptions",
    },
    // {
    //   name: "Post Your Requiement",
    //   icon: <GiPostOffice />,
    //   link: "/",
    // },
    {
      name: "Add Products",
      icon: <GrProductHunt />,
      link: "/MyProducts",
    },
    {
      name: "Add Banners",
      icon: <FaUserCheck />,
      link: "/mybanner",
    },

    {
      name: "Add New Arrival",
      icon: <MdLocalOffer />,
      link: "/View/My-Promotions",
    },
    {
      name: "Add Flash-Sales",
      icon: <MdLocalOffer />,
      link: "/View/My-FlashSales",
    },
    // {
    //   name: "Verified Business Buyer",
    //   icon: <FaUserCheck />,
    //   link: "/",
    // },
    // {
    //   name: "Products/Services Directory",
    //   icon: <MdOutlineDocumentScanner />,
    //   link: "/",
    // },
    // {
    //   name: "My Orders",
    //   icon: <AiFillGift />,
    //   link: "/",
    // },
    {
      name: "My Leads",
      icon: <FaUserCheck />,
      link: "/MyLeads",
    },

    {
      name: "My Reels",
      icon: <FaUserCheck />,
      link: "/View/My-Reel",
    },

    {
      name: "Recent Activity",
      icon: <MdSportsEsports />,
      link: "/Recent-Activities",
    },

    // {
    //   name: "Promoted Dealerships opportunity",
    //   icon: <FaUserCheck />,
    //   link: "/mydealerships",
    // },
    // {
    //   name: "Dealerships opportunity Leads",
    //   icon: <FaUserCheck />,
    //   link: "/mydealershipsusers",
    // },
    // {
    //   name: "My Applied Opportunity",
    //   icon: <FaUserCheck />,
    //   link: "/mydealershipsusers",
    //   // link: "/myappliedopp",

    // },
    // {
    //   name: "Notifications",
    //   icon: <AiTwotoneSetting />,
    //   link: "/notifications",
    // },

    // {
    //   name: "Added Oppertunity",
    //   icon: <MdLocalOffer />,
    //   link: "/add-oppotunity",
    // }
  ]);

  const { data: categories } = useCategories({ level: 1 });

  const [showSignIn, setShowSignIn] = useState(false);
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [searchResultArr, setSearchResultArr] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [loginByEmail, setLoginByEmail] = useState(false);

  const [email, setEmail] = useState("");
  const [otpsent, setotpsent] = useState(false);
  const [totalNotification, settotalNotification] = useState(0);
  const [signInModal, setSignInModal] = useState(false);
  const [mobile, setmobile] = useState("");
  const [otp, setotp] = useState("");
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([
    {
      img: images.country_1,
      prefix: "+91",
      active: true,
    },
    {
      img: images.country_2,
      prefix: "+92",
      active: false,
    },
    {
      img: images.country_3,
      prefix: "+93",
      active: false,
    },
  ]);
  const [showCountry, setShowCountry] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    img: countryList[0].img,
    prefix: countryList[0].prefix,
  });
  const [countryTimeout, setCountryTimeout] = useState(null);
  const [categoryArr, setcategoryArr] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
    console.log("isAuthorized", isAuthorized);

  const [name, setname] = useState();

  const handleGetProducts = async () => {
    try {
      let { data: res } = await getUnreadNotificationsCount({
        userId: userObj?._id,
      });
      settotalNotification(res.unreadCount);
    } catch (err) {
      errorToast(err);
    }
  };

  useEffect(() => {
    let intervalId;
    if (userObj && userObj?._id) {
      handleGetProducts(); // Fetch initial count

      // Set up an interval to fetch the count continuously
      // intervalId = setInterval(() => {
      //   handleGetProducts();
      // }, 111000); // Fetch every 5 seconds (5000 milliseconds)
    }

    // // Cleanup function to clear the interval when the component unmounts or userObj changes
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [userObj,location.pathname]);

  const handleRegister = () => {
    setSignInModal(false);
    return redirect("/Register");
  };

  const handleGetUser = async () => {
    let decodedToken = await getDecodedToken();
    let user = decodedToken?.userData?.user;
    if (user) {
      setname(user.name);
    }
  };

  useEffect(() => {
    setSignInModal(false);
    if (getToken()) {
      handleGetUser();
    }
  }, [isAuthorized]);

  const handleLogout = () => {
    // deleteToken()
    dispatch(logoutUser());
    setotp("");
    setmobile("");
    setotpsent(false);
    // localStorage.removeItem('PLYWOOD_BAZAR_AUTH');
    localStorage.clear();
    // setIsAuthorized(false)
  };

  const handleSearchText = (value) => {
    setSearchText(value);
    debounceSearch(value);
  };
  const debounceSearch = useCallback(
    debounce((nextVal) => handleSearchFromDb(nextVal), 1000),
    [searchType]
  );

  const handleSearchFromDb = async (value) => {
    try {
      console.log(searchType, "handleSearchText");

      if (value != "") {
        const { data: res } = await searchVendorFromDb(`search=${value}`);
        if (res) {
          console.log(res.data, "handleSearchText vendor");
          setShowSearchBar(true);
          setSearchResultArr(res.data);
        } else {
          setShowSearchBar(true);
          setDisplaySearchResults([]);
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const checkSearchMode = () => {
    return searchList.find((el) => el.checked)?.name;
  };

  useEffect(() => {
    if (searchList.some((el) => el.checked)) {
      console.log(checkSearchMode());
    }
  }, [showSearchBar, searchList]);

  const handleCheckItem = (obj) => {
    let tempArr = searchList.map((el) => {
      if (el.name == obj.name) {
        el.checked = true;
      } else {
        el.checked = false;
      }

      return el;
    });
    setSearchText("");
    setSearchType(obj.type);
    setSearchList([...tempArr]);
    setSearchResultArr([]);
  };

  useEffect(() => {
    console.log(searchType, "searchVendor ");
  }, [searchType]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pickerRef = useRef(null);
  const pickerRef1 = useRef(null);

  useLayoutEffect(() => {
    window.addEventListener("resize", function updateSize() {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () =>
      window.removeEventListener("resize", function updateSize() {
        setWindowWidth(window.innerWidth);
      });
  }, [window]);

  useEffect(() => {
    if (
      pickerRef.current !== null &&
      pickerRef1.current !== null &&
      pickerRef.current.input &&
      pickerRef1.current.input
    ) {
      if (windowWidth > 992) {
        pickerRef.current.input.readOnly = false;
      } else {
        pickerRef.current.input.readOnly = true;
      }
      if (windowWidth > 992) {
        pickerRef1.current.input.readOnly = false;
      } else {
        pickerRef1.current.input.readOnly = false;
      }
    }
    console.log("asd");
  }, [windowWidth, pickerRef, pickerRef1]);

  useEffect(() => {
    if (searchParams.get("loginTriggered")) {
      setSignInModal(true);
      setSearchParams((prev) => console.log(prev, "prev"));
    }
  }, [searchParams.get("loginTriggered")]);

  const wrapperRef = useRef(null);
  const wrapperLoggedOutRef = useRef(null);

  useEffect(() => {
    function handleClickOutsideLoggedOut(event) {
      if (
        wrapperLoggedOutRef.current &&
        !wrapperLoggedOutRef.current.contains(event.target)
      ) {
        setShowSignIn(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutsideLoggedOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideLoggedOut);
    };
  }, [wrapperLoggedOutRef, wrapperLoggedOutRef.current]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSignIn(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, wrapperRef.current]);

  const [navbartollge, setNavbartollge] = useState(false);
  const toggleOffCanvas = () => {
    setNavbartollge((navbartollge) => !navbartollge);
  };

  const [megamenu, setMegamenu] = useState(false);

  // useEffect(() => {
  //   if (location.pathname == "/") {
  //     setShowcatgoryNav(true);
  //     setmobileshowmenu(false);
  //   } else {
  //     setShowcatgoryNav(false);
  //     setmobileshowmenu(true);
  //   }
  //   console.log(location, "navigation");
  // }, [location]);

  const [mobileshowmenu, setmobileshowmenu] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowSearchBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);
  return (
    <>
      <>
        {windowWidth > 992 ? (
          <Container fluid className="Header sticky-top">
            <div className="Sub_Headere py-2 ">
              <div className="main_logo d-inline-flex align-items-center mx-5">
                <Link to="/" className="navbar-brand d-flex align-items-center">
                  <img className="logo" src={logo} alt="" />
                </Link>
              </div>
              <div className="social_icons col-lg-6 px-5 text-end d-inline-flex align-items-center justify-content-end">
                <div className="d-flex">
                  <a
                    className="icn p-2 btn-social rounded-circle mx-2"
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100063861976220&mibextid=LQQJ4d"
                  >
                    <img src={fb} className=" img-fluid" />
                  </a>
                  <a
                    className="icn p-2 btn-social rounded-circle mx-2"
                    target="_blank"
                    href="https://www.instagram.com/plywoodbazar.com_?igsh=MXJma2draXU1eXJ1MQ=="
                  >
                    <img src={inst} className=" img-fluid" />
                  </a>
                  <a
                    className="icn p-2 btn-social rounded-circle mx-2"
                    target="_blank"
                    href="https://www.youtube.com/@DipparvVentures"
                  >
                    <img src={yout} className=" img-fluid" />
                  </a>
                  <a
                    className="icn p-2 btn-social rounded-circle mx-2"
                    target="_blank"
                    href="https://wa.me/+919403574184"
                  >
                    <img src={whp} className=" img-fluid" />
                  </a>
                </div>
                <a
                  className="Dbtn btn-primary rounded-pill ms-3 py-2 ls-3  px-4"
                  href="https://play.google.com/store/apps/details?id=com.plywoodbazar&pli=1"
                >
                  Download App
                </a>
              </div>
            </div>
            <div className="Navbar ">
              <div className="Sub_navbar">
                <Navbar className="w-100">
                  <Container fluid>
                    <div className="d-flex align-items-center justify-content-center w-100 flex-wrap">
                      <div className="contact d-flex align-items-center flex-wrap">
                        {/* <a className="callicn rounded-circle mx-2" href="">
                            <IoCallOutline />
                          </a>
                          <p
                            className="me-3 mt-3 ms-1 text-white"
                            style={{ fontSize: "15px" }}
                          >+91
                            <a href="tel:948323445243" className="text-white" style={{ textDecoration: "none" }}>
                              &nbsp; 9403574184
                            </a>
                          </p>*/}
                        {/* <Button className="navbtn rounded-pill me-2">
                          <Link to="/Subscription" className="text-white">
                            <FaHome />
                          </Link>
                        </Button> */}
                        <Link to="/">
                          <a
                            className="icn p-2 btn-social rounded-circle mx-2"
                            target="_blank"
                          >
                            {/* <img src={whp} className=" img-fluid" /> */}
                            <FaHome />
                          </a>
                        </Link>

                        {/* <p
                          className="number me-5 mt-3 text-white"
                          style={{ fontSize: "15px" }}
                        >
                          832344524394
                        </p> */}
                      </div>
                      <Form
                        className="Search_section d-flex me-3 justify-content-end flex-grow-1 position-relative"
                        onClick={() => {
                          setSearchBy(!searchBy);
                        }}
                        onFocus={() => setShowSearchBar(true)}
                        ref={formRef}
                      >
                        <Form.Control
                          type="search"
                          className="searchbar rounded-pill"
                          placeholder={checkSearchMode()}
                          aria-label="Search"
                          value={searchText}
                          onChange={(e) => handleSearchText(e.target.value)}
                        />
                        <a
                          className="searchicn position-absolute top-50 end-0 translate-middle-y me-1 text-white d-inline-flex align-items-center justify-content-center"
                          href={`/Shop?search=${searchText}`}
                        >
                          <FiSearch />
                        </a>
                        {searchText != "" && showSearchBar && (
                          <div className="searchBox listsearch">
                            <div
                              className="searchBoxCloseIcon"
                              onClick={() => {
                                setShowSearchBar(false);
                              }}
                            >
                              X
                            </div>
                            {searchResultArr && searchResultArr.length > 0 ? (
                              searchResultArr.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mb-2"
                                    style={{
                                      borderBottom: "1px solid #bf9f65",
                                    }}
                                  >
                                    {/* <Link
                                      to={`/Supplier/${el?._id}`}
                                      onClick={() => setShowSearchBar(false)}
                                      onFocus={() => setShowSearchBar(true)}
                                    >*/}
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#000",
                                      }}
                                      onClick={() => {
                                        if (!isAuthorized) {
                                          // If the user is not authorized, show the sign-in modal
                                          openSignModal();
                                        } else {
                                          navigate(`Supplier/${el?._id}`);
                                        }
                                      }}
                                    >
                                      <p className="mb-0 pb-0">
                                        {el?.searchName}
                                      </p>
                                      <p
                                        className="mb-0 pb-0"
                                        style={{
                                          color: "#8b8b7f",
                                          fontSize: "0.8rem",
                                          marginTop: "-9px",
                                        }}
                                      >
                                        {el.label}
                                      </p>
                                    </span>
                                    {/*</Link>*/}
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                {/* <Link to={`/ShopDetail/${el?.slug}`} onFocus={() => setShowSearchBar(true)}> */}
                                <p>No results found</p>
                                {/* </Link> */}
                              </div>
                            )}
                          </div>
                        )}
                      </Form>
                      <Link to="/Shop">
                        <a
                          className="icn p-2  me-2 rounded-circle "
                          target="_blank"
                          style={{
                            backgroundColor: "#bf9f65",
                            border: "3px solid #fff",
                          }}
                        >
                          {/* <img src={whp} className=" img-fluid" /> */}
                          <FaFilter color="#fff" size="15" />
                        </a>
                      </Link>
                      <div className=" d-flex align-items-center flex-wrap me-5">
                        {!isAuthorized ? (
                          <Link
                            to="/Register"
                            className=" navbtn Register rounded-pill me-2 text-white"
                          >
                            Register
                          </Link>
                        ) : (
                          <Link
                            to="/Subscription"
                            className=" navbtn rounded-pill me-2text-white"
                          >
                            Subscription
                          </Link>
                        )}

                        {isAuthorized && (
                          <a className="nav-link position-relative">
                            <Link to="/notifications">
                              <a className="icn p-2 btn-social rounded-circle mx-2">
                                <LuBellRing />
                              </a>
                            </Link>
                            {totalNotification > 0 && (
                              <span className="notification-badge">
                                {totalNotification}
                              </span>
                            )}
                          </a>
                        )}
                        {isAuthorized ? (
                          <div className="sign-in-btn">
                            <div
                              className="custom-search"
                              onClick={() => setShowSignIn(!showSignIn)}
                            >
                              <div className=" d-flex align-items-center">
                                <div>
                                  <MdAccountCircle
                                    className=" Acc_icn btn-social rounded-circle mx-2"
                                    style={{ fontSize: "2rem" }}
                                  />
                                </div>
                                <div className=" text-white">
                                  {userObj?.companyObj?.name
                                    ? userObj?.companyObj?.name
                                    : userObj?.name}
                                  <BiChevronDown />
                                </div>
                              </div>

                              <div ref={wrapperRef}>
                                <ul
                                  style={{
                                    display: showSignIn ? "block" : "none",
                                  }}
                                  className={`custom-search-list`}
                                >
                                  {signInList.map((item, i) => {
                                    return (
                                      <>
                                        <li key={i}>
                                          <Link
                                            to={item.link}
                                            className={`userprofileoptionstext ${
                                              item.name ===
                                              "Promoted Dealerships opportunity"
                                                ? "promo_del_opp"
                                                : ""
                                            }`}
                                          >
                                            <div className="userprofileoptionsicon">
                                              {item.icon}
                                            </div>
                                            {item.name}
                                          </Link>
                                        </li>
                                      </>
                                    );
                                  })}
                                  {/* <Link to='/add-oppotunity'><li>Added Opportunity</li></Link> */}
                                  {(forchecking_type ===
                                    "MANUFACTURER/IMPORTER" ||
                                    forchecking_type === ROLES.DISTRIBUTOR ||
                                    forchecking_type === ROLES.CONTRACTOR) && (
                                    <>
                                      <li>
                                        <Link
                                          to="/mydealerships"
                                          className={`userprofileoptionstext promo_del_opp `}
                                        >
                                          <div className="userprofileoptionsicon">
                                            <FaUserCheck />
                                          </div>
                                          Add Franchise Opportunities
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/myappliedopp"
                                          className={`userprofileoptionstext `}
                                        >
                                          <div className="userprofileoptionsicon">
                                            <FaUserCheck />
                                          </div>
                                          Franchise opportunity Lead
                                        </Link>
                                      </li>
                                    </>
                                  )}

                                  <li onClick={() => handleLogout()}>
                                    <a style={{ cursor: "pointer" }}>
                                      <div className="icon">
                                        <AiOutlineLogout />
                                      </div>
                                      Logout
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="sign-in-btn"
                            onClick={() => setShowSignIn(!showSignIn)}
                          >
                            <div className="custom-search">
                              <div
                                className="text-dark d-flex align-items-center"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                {windowWidth > 992 ? (
                                  <>
                                    <div className=" d-flex align-items-center">
                                      <div>
                                        <MdAccountCircle
                                          className=" Acc_icn btn-social rounded-circle mx-2"
                                          style={{ fontSize: "2rem" }}
                                        />
                                      </div>
                                      <div className=" text-white">
                                        Log In
                                        <BiChevronDown />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <img src={images.user} alt="" />
                                  </>
                                )}
                              </div>
                              <div ref={wrapperLoggedOutRef}>
                                <ul
                                  style={{
                                    display: showSignIn ? "block" : "none",
                                  }}
                                  className={
                                    showSignIn
                                      ? "custom-search-list-login"
                                      : "custom-search-list"
                                  }
                                >
                                  <li className="text-center bottom-li">
                                    <button
                                      className="yellow-bg btn text-white"
                                      onClick={() => openSignModal()}
                                    >
                                      Log In
                                    </button>
                                    <p className="text text-dark">
                                      New to Plywood Bazar?
                                      <Link
                                        to="/Register"
                                        className="brown fw-semibold justify-content-center"
                                      >
                                        Register here
                                      </Link>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* {isAuthorized && (
                          <a className="nav-link position-relative" href="/notifications">
                            <FaBell style={{ fontSize: 22 }} />
                            {totalNotification > 0 && <span>{totalNotification}</span>}
                          </a>
                        )} */}
                      </div>
                    </div>
                  </Container>
                </Navbar>
              </div>
            </div>
          </Container>
        ) : (
          <>
            <header className="reverheadermobile sticky-top bg-light">
              <div className="middlebar_new">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <Link to="/" className="navbar-brand d-lg-block d-none">
                        <img
                          src={images.logo}
                          alt=""
                          className="main-logo img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="middlebody">
                        <div className="row align-items-center">
                          <div className="col-8 col-lg-6">
                            <form
                              className="search-bar"
                              onClick={() => {
                                setSearchBy(!searchBy);
                              }}
                              onFocus={() => setShowSearchBar(true)}
                            >
                              <div className="custom-search mobileserach ">
                                <button
                                  className="yellow-bg btn text-white h-100 rounded-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/Shop");
                                  }}
                                >
                                  Search
                                </button>
                              </div>
                              <div className="form-control flex-1">
                                <input
                                  type="search"
                                  placeholder={checkSearchMode()}
                                  aria-label="Search"
                                  value={searchText}
                                  onChange={(e) =>
                                    handleSearchText(e.target.value)
                                  }
                                />
                                <div className="icon">
                                  <BiSearch />
                                </div>
                              </div>
                              {searchText != "" && showSearchBar && (
                                <div className="searchBox listsearch">
                                  <div
                                    className="searchBoxCloseIcon"
                                    onClick={() => {
                                      setShowSearchBar(false);
                                    }}
                                  >
                                    X
                                  </div>
                                  {searchResultArr &&
                                  searchResultArr.length > 0 ? (
                                    searchResultArr.map((el, index) => {
                                      return (
                                        <div key={index}>
                                          <Link
                                            to={`/Supplier/${el?._id}`}
                                            onClick={() =>
                                              setShowSearchBar(false)
                                            }
                                            onFocus={() =>
                                              setShowSearchBar(true)
                                            }
                                          >
                                            <p className="mb-0 pb-0">
                                              {el?.searchName}
                                            </p>
                                            <p
                                              className="mb-0 pb-0"
                                              style={{
                                                color: "#8b8b7f",
                                                fontSize: "0.8rem",
                                                marginTop: "-9px",
                                              }}
                                            >
                                              {el.label}
                                            </p>
                                          </Link>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div>
                                      <p>No results found</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </form>
                          </div>
                          <div className="col-4 col-lg-6">
                            <div className="flex_row d-flex mobile_right">
                              {windowWidth > 992 ? (
                                <>
                                  <Nav>
                                    <NavLink className="nav-link" to="/">
                                      Home
                                    </NavLink>
                                    {categories &&
                                      categories?.data &&
                                      categories?.data.map(
                                        (category, index) => (
                                          <NavLink
                                            key={category?._id}
                                            className="nav-link"
                                            to={`/Shop?categories=${category._id}`}
                                          >
                                            {category?.name}
                                          </NavLink>
                                        )
                                      )}

                                    <NavLink
                                      className="nav-link"
                                      to="/View/My-Tickets"
                                    >
                                      Help
                                    </NavLink>

                                    <NavLink
                                      className="nav-link"
                                      to="/View/blogs"
                                    >
                                      Blogs
                                    </NavLink>
                                    {isAuthorized && (
                                      <a
                                        className="nav-link position-relative"
                                        href="/notifications"
                                      >
                                        <FaBell style={{ fontSize: 22 }} />

                                        <span className="notification-badge">
                                          {totalNotification}
                                        </span>
                                      </a>
                                    )}
                                  </Nav>
                                </>
                              ) : (
                                ""
                              )}

                              {isAuthorized ? (
                                <div className="sign-in-btn">
                                  <div
                                    className="custom-search"
                                    onClick={() => setShowSignIn(!showSignIn)}
                                  >
                                    {/* <div className=" d-flex align-items-center">
                                      <div>
                                        <MdAccountCircle className=" Acc_icn btn-social rounded-circle mx-2" style={{ fontSize: "2rem",marginLeft:'40px' }} />
                                      </div>
                                      <div className=" text-white">
                                        {userObj?.name}<BiChevronDown />
                                      </div>
                                    </div> */}
                                    <button className="text-dark d-flex align-items-center">
                                      <img src={images.user} alt="" />
                                      {userObj?.companyObj?.name
                                        ? userObj?.companyObj?.name
                                        : userObj?.name}
                                      <BiChevronDown />
                                    </button>
                                    <div ref={wrapperRef}>
                                      <ul
                                        style={{
                                          display: showSignIn
                                            ? "block"
                                            : "none",
                                        }}
                                        className={`custom-search-list`}
                                      >
                                        {signInList.map((item, i) => {
                                          return (
                                            <li key={i}>
                                              <Link to={item.link}>
                                                <div className="icon">
                                                  {item.icon}
                                                </div>
                                                {item.name}
                                              </Link>
                                            </li>
                                          );
                                        })}

                                        <li onClick={() => handleLogout()}>
                                          <a style={{ cursor: "pointer" }}>
                                            <div className="icon">
                                              <AiOutlineLogout />
                                            </div>
                                            Logout
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="sign-in-btn"
                                  onClick={() => setShowSignIn(!showSignIn)}
                                >
                                  <div className="custom-search">
                                    <button
                                      className="text-dark d-flex align-items-center"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      {windowWidth > 992 ? (
                                        <>
                                          <img src={images.user} alt="" /> Log
                                          In <BiChevronDown />
                                        </>
                                      ) : (
                                        <>
                                          <img src={images.user} alt="" />
                                        </>
                                      )}
                                    </button>
                                    <div ref={wrapperLoggedOutRef}>
                                      <ul
                                        style={{
                                          display: showSignIn
                                            ? "block"
                                            : "none",
                                        }}
                                        className={`custom-search-list`}
                                      >
                                        <li className="text-center bottom-li">
                                          <button
                                            className="yellow-bg btn text-white"
                                            onClick={() => openSignModal()}
                                          >
                                            Log In
                                          </button>
                                          <p className="text text-dark">
                                            New to Plywood Bazar?
                                            <Link
                                              to="/Register"
                                              className="brown fw-semibold justify-content-center"
                                            >
                                              Register here
                                            </Link>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* {mobileshowmenu && (
                <div className="mobileheader d-lg-none">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <Link to="/">
                          <img
                            src={images.logo}
                            alt=""
                            className="img-fluid mobile-logo d-lg-none"
                          />
                        </Link>
                      </div>
                      <div className="col-6">
                        <Navbar
                          onToggle={() => toggleOffCanvas()}
                          expanded={navbartollge}
                          expand="lg"
                          className="bottom-bar topnavigation p-0"
                        >
                          <Container fluid>
                            <Navbar.Toggle aria-controls="bottomNavbar-expand-lg" />

                            <Navbar.Offcanvas
                              className="mainnavigation"


                              id={`offcanvasNavbar-expand-lg`}
                              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                              placement="end"
                            >
                              <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="bottomNavbarLabel-expand-lg">
                                  <img
                                    src={images.logo}
                                    alt=""
                                    className="img-fluid mobile-logo d-lg-none"
                                  />
                                </Offcanvas.Title>
                              </Offcanvas.Header>
                              <Offcanvas.Body>
                                <Nav className="flex-grow-1">
                                  <>
                                   
                                    <Nav className="mobile_botm_border">
                                      <NavLink
                                        onClick={() => (setNavbartollge = false)}
                                        className="nav-link"
                                        to="/"
                                      >
                                        Home
                                      </NavLink>
                                      <NavLink
                                        onClick={() => (setNavbartollge = false)}
                                        className="nav-link"
                                        to="/Shop"
                                      >
                                        View Products
                                      </NavLink>

                                      <NavLink
                                        onClick={() => (setNavbartollge = false)}
                                        className="nav-link"
                                        to="/View/My-Tickets"
                                      >
                                        Help
                                      </NavLink>

                                      <NavLink
                                        onClick={() => (setNavbartollge = false)}
                                        className="nav-link"
                                        to="/View/blogs"
                                      >
                                        Blogs
                                      </NavLink>
                                    </Nav>
                                  </>
                                </Nav>
                              </Offcanvas.Body>
                            </Navbar.Offcanvas>
                          </Container>
                        </Navbar>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {showcatgoryNav && (
                <Navbar
                  onToggle={() => toggleOffCanvas()}
                  expanded={navbartollge}
                  expand="lg"
                  // ref={wrapper2Ref}
                  className="bottom-bar sticky-top topnavigation"
                >
                  <Container fluid>
                    <Navbar.Toggle aria-controls="bottomNavbar-expand-lg" />
                    <Link to="/">
                      <img
                        src={images.logo}
                        alt=""
                        className="img-fluid mobile-logo d-lg-none"
                      />
                    </Link>

                    <Navbar.Offcanvas
                      className="mainnavigation"
                      id={`offcanvasNavbar-expand-lg`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                      placement="end"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="bottomNavbarLabel-expand-lg">
                          <img
                            src={images.logo}
                            alt=""
                            className="img-fluid mobile-logo d-lg-none"
                          />
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body className="topheader_scroll">
                        <Nav className="flex-grow-1 webkitnone">
                          {windowWidth < 992 && (
                            <>
                              <Nav className="mobile_botm_border">
                                <NavLink
                                  onClick={() => (setNavbartollge = false)}
                                  className="nav-link"
                                  to="/"
                                >
                                  Home
                                </NavLink>
                                {categories &&
                                  categories?.data &&
                                  categories?.data.map((category, index) => (
                                    <a
                                      key={category?._id}
                                      className="nav-link"
                                      href={`/Shop?categories=${category._id}`}
                                    >
                                      {category?.name}
                                    </a>
                                  ))}
                                <NavLink
                                  onClick={() => (setNavbartollge = false)}
                                  className="nav-link"
                                  to="/Shop"
                                >
                                  View Products
                                </NavLink>

                                <NavLink
                                  onClick={() => (setNavbartollge = false)}
                                  className="nav-link"
                                  to="/View/My-Tickets"
                                >
                                  Help
                                </NavLink>

                                <NavLink
                                  onClick={() => (setNavbartollge = false)}
                                  className="nav-link"
                                  to="/View/blogs"
                                >
                                  Blogs
                                </NavLink>
                              </Nav>
                            </>
                          )}
                        </Nav>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              )}
            </header>
          </>
        )}
      </>
    </>
  );
}

export default Header;
