import moment from "moment";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createFlashSales, getFlashSalebyId, updateFlashSalebyId } from "../../services/FlashSales.service";
import { getAllProducts } from "../../services/Product.service";
import { getUserById } from "../../services/User.service";
import { errorToast, successToast } from "../Utility/Toast";

// Zod schema definition
const flashSaleSchema = z.object({
  productId: z.string().min(1, "Please select a product"),
  price: z.string().min(1, "Price is required"),
  salePrice: z.string().min(1, "Sale price is required"),
  discountType: z.string().default("Percentage"),
  discountValue: z.string().default("0"),
  pricetype: z.string().default("per Nos/sheet"),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  description: z.string().max(100, "Description cannot be more than 100 characters").optional(),
}).refine((data) => {
  const priceNum = parseFloat(data.price);
  const discountNum = parseFloat(data.discountValue);
  if (data.discountType === "Percentage" && discountNum > 100) return false;
  if (data.discountType === "Amount" && discountNum >= priceNum) return false;
  return true;
}, {
  message: "Invalid discount value",
  path: ["discountValue"],
});

export default function AddFlashSale() {
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth.user);
  const [searchParams] = useSearchParams();
  const [isEditingModeOn, setIsEditingModeOn] = React.useState(false);
  const [productArr, setProductArr] = React.useState([]);
  const [userDataObj, setUserDataObj] = React.useState({});
  const [userSubscriptionExpired, setUserSubscriptionExpired] = React.useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(flashSaleSchema),
    defaultValues: {
      productId: "",
      price: "0",
      salePrice: "0",
      discountType: "Percentage",
      discountValue: "0",
      pricetype: "per Nos/sheet",
      startDate: moment().format("YYYY-MM-DDThh:mm:ss"),
      endDate: moment().format("YYYY-MM-DDThh:mm:ss"),
      description: "",
    },
  });

  const percentage = (percent, total) => (percent / 100) * total;
  const discountType = watch("discountType");
  const discountValue = watch("discountValue");
  const price = watch("price");

  const handleGetUser = async () => {
    try {
      let { data: res } = await getUserById(userObj._id);
      if (res.data) {
        setUserDataObj(res.data);
        setUserSubscriptionExpired(res.data.userSubscriptionExpired);
        if (res.data.saleDays > 0) {
          const newDate = moment(new Date()).add(res.data.saleDays, 'days');
          setValue("endDate", moment(newDate).format("YYYY-MM-DDThh:mm:ss"));
        }
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const onSubmit = async (data) => {
    try {
      const obj = {
        userId: userObj._id,
        productId: data.productId,
        price: parseFloat(data.price),
        discountType: data.discountType,
        discountValue: parseFloat(data.discountValue),
        pricetype: data.pricetype,
        salePrice: parseFloat(data.salePrice),
        endDate: data.endDate,
        startDate: data.startDate,
        description: data.description,
      };

      if (isEditingModeOn) {
        const { data: res } = await updateFlashSalebyId(searchParams.get("id"), obj);
        if (res.message) successToast('Flash Sale updated successfully');
      } else {
        const { data: res } = await createFlashSales(obj);
        if (res.message) {
          successToast('Flash Sale added successfully');
          navigate("/View/My-FlashSales");
        }
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetProducts = async () => {
    try {
      let query = `page=${1}&perPage=${10000}&userId=${userObj?._id}`;
      let { data: res } = await getAllProducts(query);
      if (res.data) setProductArr(res.data);
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetSaleById = async () => {
    try {
      let { data: res } = await getFlashSalebyId(searchParams.get("id"));
      if (res.data) {
        reset({
          productId: res.data.productId._id,
          price: res.data.price.toString(),
          salePrice: res.data.salePrice.toString(),
          discountType: res.data.discountType,
          discountValue: res.data.discountValue.toString(),
          pricetype: res.data.pricetype || "per Nos/sheet",
          startDate: moment(res.data.startDate).format("YYYY-MM-DDThh:mm:ss"),
          endDate: moment(res.data.endDate).format("YYYY-MM-DDThh:mm:ss"),
          description: res.data.description || "",
        });
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleDiscountValueChange = (value) => {
    if (Number(value) >= 0) {
      let tempValue = 0;
      if (discountType === "Percentage" && Number(value) <= 100) {
        tempValue = Number(price) - percentage(Number(value), Number(price));
      } else if (discountType === "Amount" && Number(value) < Number(price)) {
        tempValue = Number(price) - Number(value);
      }
      setValue("discountValue", value);
      setValue("salePrice", tempValue.toFixed(2));
    } else {
      errorToast("Discount cannot be less than 0");
    }
  };

  useEffect(() => {
    handleGetProducts();
    handleGetUser();
    if (searchParams.get("id")) {
      setIsEditingModeOn(true);
      handleGetSaleById();
    }
  }, []);

  return (
    <div className="container">
      <div className="row pt-3">
        <h3 className="heading ">
          {isEditingModeOn ? "Edit" : "Add"} Flash sale
        </h3>

        {userDataObj?.numberOfSales > 0 && !userSubscriptionExpired && !userDataObj?.isBlocked ? (
          <div className="col-6 col-sm-4 justify-content-end"></div>
        ) : (
          userDataObj?.numberOfSales <= 0 && userSubscriptionExpired && !userDataObj?.isBlocked && (
            <div className="col-6 col-sm-4 justify-content-end">
              <div className="row d-flex justify-content-end">
                <button className="theme-outline-button" onClick={() => navigate("/Subscription")}>
                  Purchase a subscription
                </button>
              </div>
            </div>
          )
        )}
        {(userSubscriptionExpired || userDataObj?.numberOfSales <= 0) && (
          <div className="container">
            <div className="row">
              <div className="col-6 justify-content-end">
                You do not have a valid subscription, subscribe one to add a flash sale
              </div>
              <div className="col-6 justify-content-end">
                <div className="row d-flex justify-content-end">
                  <button className="theme-outline-button" onClick={() => navigate("/Subscription")}>
                    Purchase a subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {userDataObj?.isBlocked && (
          <div className="subscription-description">
            Your subscription has been blocked by admin please contact admin for further details
          </div>
        )}
        <div className="col-12 col-md-12 mb-5">
          <div className="right">
            {!(userSubscriptionExpired || userDataObj?.numberOfSales <= 0) && (
              <form className="form row profile-section-container padingflashsales" onSubmit={handleSubmit(onSubmit)}>
                <h4 style={{ color: "rgba(0,0,0,0.7)" }}>Product Details</h4>

                <div className="col-md-6">
                  <label>
                    Product <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="productId"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="form-control"
                        onChange={(e) => {
                          field.onChange(e);
                          const tempObj = productArr.find((el) => el._id === e.target.value);
                          setValue("price", tempObj?.sellingprice || "0");
                          setValue("discountValue", "0");
                          setValue("salePrice", "0");
                        }}
                      >
                        <option value="">Please Select Product</option>
                        {productArr.map((el) => (
                          <option key={el._id} value={el._id}>
                            {el.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.productId && <span className="text-danger">{errors.productId.message}</span>}
                </div>

                <div className="col-md-6">
                  <label>
                    Discount type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="discountType"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="form-control"
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("discountValue", "0");
                          setValue("salePrice", "0");
                        }}
                      >
                        <option value="Percentage">Percentage</option>
                        <option value="Amount">Amount</option>
                      </select>
                    )}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Price <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <input {...field} type="number" className="form-control"  />
                    )}
                  />
                  {errors.price && <span className="text-danger">{errors.price.message}</span>}
                </div>
                <div className="col-md-6">
                  <label>
                    Discount Value <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="discountValue"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        className="form-control"
                        onChange={(e) => handleDiscountValueChange(e.target.value)}
                      />
                    )}
                  />
                  {errors.discountValue && <span className="text-danger">{errors.discountValue.message}</span>}
                </div>

               

                <div className="col-md-6">
                  <label>
                    Sale Price <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="salePrice"
                    control={control}
                    render={({ field }) => (
                      <input {...field} type="number" className="form-control" disabled />
                    )}
                  />
                  {errors.salePrice && <span className="text-danger">{errors.salePrice.message}</span>}
                </div>

                <div className="col-md-6">
                  <label>Select Type</label>
                  <Controller
                    name="pricetype"
                    control={control}
                    render={({ field }) => (
                      <select {...field} className="form-control">
                        <option value="per Nos/sheet">per Nos/sheet</option>
                        <option value="per sq.ft">per sq.ft</option>
                        <option value="per sq.mt">per sq.mt</option>
                        <option value="per Rn.ft">per Rn.ft</option>
                        <option value="per Cu.ft">per Cu.ft</option>
                        <option value="p per Cu.mt">p per Cu.mt</option>
                      </select>
                    )}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Start Date <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <input {...field} type="datetime-local" className="form-control" />
                    )}
                  />
                  {errors.startDate && <span className="text-danger">{errors.startDate.message}</span>}
                </div>

                <div className="col-md-6">
                  <label>Description</label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <input {...field} type="text" className="form-control" />
                    )}
                  />
                  {errors.description && <span className="text-danger">{errors.description.message}</span>}
                </div>

                <div className="col-md-12">
                  <button type="submit" className="btn btn-custom btn-yellow mt-2">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}