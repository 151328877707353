import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAllCategories, getNestedCategories } from "../../services/Category.service";
import {
  AddProduct,
  getProductById,
  updateProductApi,
} from "../../services/Product.service";
import { addBrandApi, getBrandApi } from "../../services/brand.service";
import { generateImageUrl } from "../../services/url.service";
import { convertFileToBase64 } from "../Utility/FileConverterToBase64";
import FileInput from "../Utility/FileUploadCropper";
import { errorToast, successToast } from "../Utility/Toast";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

// Zod schema definition
const productSchema = z.object({
  name: z.string().min(1, "Name is required"),
  category: z.string().min(1, "Category is required"),
  brand: z.string().optional(),
  price: z.string().min(1, "Price is required"),
  sellingprice: z.string().optional(),
  pricetype: z.string().default("per Nos/sheet"),
  thickness: z.string().optional(),
  application: z.string().optional(),
  grade: z.string().optional(),
  wood: z.string().optional(),
  glue: z.string().optional(),
  warranty: z.string().optional(),
  longDescription: z.string().min(1, "Long description is required"),
  image: z.string().min(1, "Main image is required"),
  status: z.boolean().default(false),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isEditingModeOn, setIsEditingModeOn] = React.useState(false);
  const [brandName, setBrandName] = React.useState("");
  const [categoryArr, setCategoryArr] = React.useState([]);
  const [brandArr, setBrandArr] = React.useState([]);
  const [imageArr, setImageArr] = React.useState([{ image: "" }]);
  const [mainCategoryArr, setMainCategoryArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(productSchema),
    defaultValues: {
      name: "",
      category: "",
      brand: "",
      price: "",
      sellingprice: "",
      pricetype: "per Nos/sheet",
      thickness: "",
      application: "",
      grade: "",
      wood: "",
      glue: "",
      warranty: "",
      longDescription: "",
      image: "",
      status: false,
    },
  });

  useEffect(() => {
    handleGetBrands();
    handleGetCategory();
    if (searchParams.get("productId")) {
      setIsEditingModeOn(true);
      handleGetProductById();
    }
  }, []);

  const handleGetBrands = async () => {
    try {
      let { data: res } = await getBrandApi("status=true&page=1&perPage=1000");
      if (res.data) setBrandArr(res.data);
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getNestedCategories({ level: 1 });
      if (res.data) setCategoryArr(res.data);
    } catch (err) {
      errorToast(err);
    }
  };

  const handleGetProductById = async () => {
    try {
      let { data: res } = await getProductById(searchParams.get("productId"));
      if (res.data) {
        // Populate form with existing data
        reset({
          name: res.data.name,
          category: res.data.categoryId,
          brand: res.data.brand,
          price: res.data.price,
          sellingprice: res.data.sellingprice?.split(" ")[0] || "",
          pricetype: res.data.sellingprice?.split(" ")[1] || "per Nos/sheet",
          thickness: res.data.specification?.thickness || "",
          application: res.data.specification?.application || "",
          grade: res.data.specification?.grade || "",
          wood: res.data.specification?.wood || "",
          glue: res.data.specification?.glue || "",
          warranty: res.data.specification?.warranty || "",
          longDescription: res.data.longDescription || "",
          image: res.data.mainImage || "",
          status: res.data.status || false,
        });
        setImageArr(res.data.imageArr || [{ image: "" }]);
        setMainCategoryArr(res.data.categoryArr || []);
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const handleCategoryEvent = (value) => {
    const tempCategoryArr = [];
    categoryArr.forEach((el) => {
      if (el._id === value) {
        tempCategoryArr.push({ categoryId: el._id });
      } else if (el.subCategoryArr?.length > 0) {
        el.subCategoryArr.forEach((elx) => {
          if (elx._id === value) {
            tempCategoryArr.push({ categoryId: el._id }, { categoryId: elx._id });
          }
        });
      }
    });
    setValue("category", value);
    setMainCategoryArr(tempCategoryArr);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const productData = {
        ...data,
        sellingprice: `${data.sellingprice} ${data.pricetype}`,
        specification: {
          thickness: data.thickness,
          application: data.application,
          grade: data.grade,
          wood: data.wood,
          glue: data.glue,
          warranty: data.warranty,
        },
        imageArr: imageArr,
        categoryArr: mainCategoryArr,
      };

      let res;
      if (isEditingModeOn) {
        res = await updateProductApi(productData, searchParams.get("productId"));
      } else {
        res = await AddProduct(productData);
      }

      if (res.data.message) {
        successToast(res.data.message);
        navigate("/MyProducts");
      }
    } catch (err) {
      errorToast(err);
    } finally {
      setLoading(false);
    }
  };

  const subcategoryRender = (cateArr, dash = "") =>
    cateArr?.map((cat) => (
      <React.Fragment key={cat._id}>
        <option value={cat._id}>
          {dash}
          {cat.name}
        </option>
        {subcategoryRender(cat.subCategoryArr, dash + "--")}
      </React.Fragment>
    ));

  const handleImageAdd = () => imageArr.length < 3 && setImageArr([...imageArr, { image: "" }]);
  const handleImagesRemove = () => imageArr.length > 1 && setImageArr(imageArr.slice(0, -1));
  const handleFileSet = async (value, index) => {
    const tempArr = [...imageArr];
    tempArr[index].image = await convertFileToBase64(value);
    setImageArr(tempArr);
  };

  const HandleAddBrand = async () => {
    try {
      if (!brandName) {
        errorToast("Please Fill Brand Name");
        return;
      }
      const { data: res } = await addBrandApi({ name: brandName, status: true });
      if (res.message) {
        successToast(res.message);
        handleGetBrands();
        setOpen(false);
        setBrandName("");
      }
    } catch (err) {
      errorToast(err);
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="row pt-3">
        <div className="col-12 col-md-12">
          <div className="right frormcontainer px-5 py-4">
            <h3 className="heading formheading mb-4 text-white">
              {isEditingModeOn ? "Edit" : "Add"} Product
            </h3>
            <form className="form row" onSubmit={handleSubmit(onSubmit)}>
              <h4 className="yellow">Product Details</h4>
              <div className="col-md-6">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input {...field} className="form-control" type="text" />
                  )}
                />
                {errors.name && <span className="text-danger">{errors.name.message}</span>}
              </div>

              <div className="col-md-6">
                <label>
                  Category <span className="text-danger">*</span>
                </label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="form-control"
                      onChange={(e) => handleCategoryEvent(e.target.value)}
                    >
                      <option value="">Please Select Category</option>
                      {subcategoryRender(categoryArr)}
                    </select>
                  )}
                />
                {errors.category && <span className="text-danger">{errors.category.message}</span>}
              </div>

              <div className="col-md-6">
                <label>Brand</label>
                <div className="row">
                  <div className="col">
                    <Controller
                      name="brand"
                      control={control}
                      render={({ field }) => (
                        <select {...field} className="form-control">
                          <option value="">Please Select Brand</option>
                          {brandArr.map((el) => (
                            <option key={el._id} value={el._id}>
                              {el.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      onClick={() => setOpen(true)}
                      className="btn btn-custom btn-link-yellow"
                    >
                      Add Brand
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Price <span className="text-danger">*</span>
                </label>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <input {...field} className="form-control" type="number" />
                  )}
                />
                {errors.price && <span className="text-danger">{errors.price.message}</span>}
              </div>

              <div className="col-md-6">
                <label>Selling Price</label>
                <Controller
                  name="sellingprice"
                  control={control}
                  render={({ field }) => (
                    <input {...field} className="form-control" type="number" />
                  )}
                />
              </div>

              <div className="col-md-6">
                <label>Select Type</label>
                <Controller
                  name="pricetype"
                  control={control}
                  render={({ field }) => (
                    <select {...field} className="form-control">
                      <option value="per Nos/sheet">per Nos/sheet</option>
                      <option value="per sq.ft">per sq.ft</option>
                      <option value="per sq.mt">per sq.mt</option>
                      <option value="per Rn.ft">per Rn.ft</option>
                      <option value="per Cu.ft">per Cu.ft</option>
                      <option value="p per Cu.mt">p per Cu.mt</option>
                    </select>
                  )}
                />
              </div>

              <hr className="my-4" />
              <h4 className="yellow">Specification</h4>
              {["thickness", "application", "grade", "wood", "glue", "warranty"].map((field) => (
                <div className="col-md-6" key={field}>
                  <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                  <Controller
                    name={field}
                    control={control}
                    render={({ field: controllerField }) => (
                      <input {...controllerField} className="form-control" type="text" />
                    )}
                  />
                </div>
              ))}

              <hr className="my-4" />
              <div className="col-md-6">
                <label>
                  Main Image (width:610px and height:400px) <span className="text-danger">*</span>
                </label>
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <FileInput
                      setFile={async (e) => {
                        const base64 = await convertFileToBase64(e);
                        setValue("image", base64);
                      }}
                      file={field.value}
                      type="image"
                      previousFile={field.value?.includes("base64") ? field.value : null}
                    />
                  )}
                />
                {errors.image && <span className="text-danger">{errors.image.message}</span>}
              </div>

              <div className="col-md-6">
                <label>
                  Long Description <span className="text-danger">*</span>
                </label>
                <Controller
                  name="longDescription"
                  control={control}
                  render={({ field }) => (
                    <textarea {...field} className="form-control" />
                  )}
                />
                {errors.longDescription && (
                  <span className="text-danger">{errors.longDescription.message}</span>
                )}
              </div>

              <hr className="my-4" />
              <h4 className="yellow">Product Images</h4>
              <div className="col-md-12">
                <label>
                  Multiple Image (width:92px and height:92px)
                  <button
                    type="button"
                    onClick={handleImageAdd}
                    className="btn btn-custom btn-yellow mx-2"
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={handleImagesRemove}
                    className="btn btn-custom btn-yellow"
                  >
                    -
                  </button>
                </label>
                <div className="row">
                  {imageArr.map((el, index) => (
                    <div className="col-md-4" key={index}>
                      <FileInput
                        setFile={(e) => handleFileSet(e, index)}
                        file={el.image}
                        type="image"
                        previousFile={el.image?.includes("base64") ? el.image : null}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-custom btn-yellow mt-2"
                  style={{ width: "200px" }}
                  disabled={loading}
                >
                  {loading ? "Please Wait..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>Add Brand</h3>
            <button onClick={() => setOpen(false)} style={{ background: "none", border: "none", fontSize: "18px", cursor: "pointer" }}>
              ×
            </button>
          </div>
          <label>
            Brand Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
          <button
            type="button"
            onClick={HandleAddBrand}
            className="btn btn-custom btn-yellow mt-2"
          >
            Submit
          </button>
        </Box>
      </Modal>
    </div>
  );
}