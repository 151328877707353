import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllCategories, useCategories } from "../services/Category.service";
import {
  getSalesUsers,
  registerUser,
  getsendOTPForVerify,
  getverifyUserOTP,
} from "../services/User.service";
import {
  getCityByStateApi,
  getCountriesApi,
  getStateByCountryApi,
  useCities,
  useCountry,
  useStates,
} from "../services/location.service";
import { toastError } from "../utils/toastutill";
import FileUpload from "./Utility/FileUpload";
import { errorToast, successToast } from "./Utility/Toast";
import { ROLES_CONSTANT } from "./Utility/constant";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getCroppedImg, handleOpenImageInNewTab } from "../utils/image.utils";
import Cropper from "react-easy-crop";
import FileInput from "./Utility/FileUploadCropper";
import { convertFileToBase64 } from "./Utility/FileConverterToBase64";
import logo from "../assets/image/home/images/logo6.png";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const registerSchema = z
  .object({
    type: z.enum([
      ROLES_CONSTANT.CORPORATE,
      ROLES_CONSTANT.MANUFACTURER,
      ROLES_CONSTANT.DISTRIBUTOR,
      ROLES_CONSTANT.DEALER,
      ROLES_CONSTANT.RETAILER,
      ROLES_CONSTANT.CONTRACTOR,
    ]),
    companyName: z
      .string()
      .min(1, "Business Name is required")
      .refine((val) => !/\d/.test(val), {
        message: "Business Name cannot contain numbers",
      }),
    yearOfEstablishment: z
      .string()
      .min(1, "Year of Establishment is required")
      .regex(
        /^\d{4}$/,
        "Year of Establishment must be a valid year (4 digits)"
      ),
    category: z
      .array(z.object({ value: z.string(), label: z.string() })),
    address: z.string().min(1, "Address is required"),
    name: z.string().min(1, "Name is required"),
    email: z
      .string()
      .email("Invalid email address")
      .min(1, "Email is required"),
    mobile: z.string().regex(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    brandNames: z
      .string()
      .min(1, "Brand Names is required")
      .refine((val) => !/\d/.test(val), {
        message: "Brand Name cannot contain numbers",
      }),
    countryId: z.string().min(1, "Country is required"),
    stateId: z.string().min(1, "State is required"),
    cityId: z.string().min(1, "City is required"),
    whatsapp: z
      .string()
      .regex(/^[0-9]{10}$/, "Whatsapp number must be 10 digits")
      .optional()
      .or(z.literal("")),
    gstNumber: z.string().optional(),
    aniversaryDate: z.date().optional(),
    profileImage: z.string().optional(),
    bannerImage: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    // Conditional validation for CORPORATE type
    if (data.type === ROLES_CONSTANT.CORPORATE) {
      if (!data.gstNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "GST Number is required for Corporate",
          path: ["gstNumber"],
        });
      } else {
        // If gstNumber is provided, validate its format
        const gstRegex =
          /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1})$/;
        if (!gstRegex.test(data.gstNumber)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid GST Number",
            path: ["gstNumber"],
          });
        }
      }
      if (!data.aniversaryDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Date of Birth is required for Corporate",
          path: ["aniversaryDate"],
        });
      }
      if (!data.whatsapp) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Whatsapp number is required for Corporate",
          path: ["whatsapp"],
        });
      }
      if (!data.profileImage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Profile Image is required for Corporate",
          path: ["profileImage"],
        });
      }
      if (!data.bannerImage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Banner Image is required for Corporate",
          path: ["bannerImage"],
        });
      }
    }
  });
export const Register2 = () => {
  const [type, settype] = useState(ROLES_CONSTANT.MANUFACTURER);

  // const { data: categories } = useCategories({level:1});
  const { data: countries } = useCountry();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      type: ROLES_CONSTANT.MANUFACTURER,
      companyName: "",
      yearOfEstablishment: "",
      category: [],
      address: "",
      name: "",
      email: "",
      mobile: "",
      brandNames: "",
      countryId: "",
      stateId: "",
      cityId: "",
      whatsapp: "",
      gstNumber: "",
      aniversaryDate: new Date(),
      profileImage: "",
      bannerImage: "",
    },
    mode: "onChange",
  });

  const countryId = watch("countryId");
  const stateId = watch("stateId");
  //   const type = watch("type");
  const mobile = watch("mobile");

  const { data: states } = useStates({ countryId }, false, !!countryId);
  const { data: cities } = useCities({ stateId }, false, !!stateId);

  const editorRef = useRef(null);
  const [otp, setotp] = useState("");
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [gstCertificate, setgstCertificate] = useState("");

  // const [landline, setLandline] = useState("");
  const [aniversaryDate, setAniversaryDate] = useState(new Date());
  const [signInModal, setSignInModal] = useState(false);

  const [categoryArr, setcategoryArr] = useState([]);
  const [category, setcategory] = useState("");
  const [show, setShow] = useState(false);
  const [registerObj, setRgisterObj] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [croppedProfilePhoto, setCroppedProfilePhoto] = useState({
    x: 0,
    y: 0,
  });

  const [zoom, setZoom] = useState(1);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleProfileModalClose = () => setShowProfileModal(false);
  const handleProfileModalShow = () => setShowProfileModal(true);

  const [croppedBannerPhoto, setCroppedBannerPhoto] = useState("");

  const [showBannerModal, setShowBannerModal] = useState(false);

  const handleBannerModalClose = () => setShowBannerModal(false);
  const handleBannerModalShow = () => setShowBannerModal(true);

  const onCropChange = (newCrop) => setCroppedProfilePhoto(newCrop);
  const onZoomChange = (newZoom) => setZoom(newZoom);

  // const [type, setType] = useState("");

  const [isFormValid, setIsFormValid] = useState(true);

  const handlesendOtp = async () => {
    try {
      if (`${mobile}`.length !== 10) {
        errorToast("Please Enter Mobile Number");
        return;
      }

      let { data: res } = await getverifyUserOTP({ otp, phone: mobile });
      if (res.message) {
        // handle2();
        await handleRegister(registerObj);
        setotp("");
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };
  const resendOtp = async () => {
    try {
      let { data: otpResponse } = await getsendOTPForVerify({ phone: mobile });
      if (otpResponse.result === true) {
        successToast("Verification OTP sent to your phone.");
      } else {
        errorToast("Failed to send OTP.");
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  const handleRegister = async (obj) => {
    console.log(obj, "companycompanycompanycompany");
    try {
      let { data: res } = await registerUser(obj);
      if (res.result == false) {
        try {
          // Call the sendOTPForVerify API if the user is not verified
          let { data: otpResponse } = await getsendOTPForVerify({
            phone: mobile,
          });
          if (otpResponse.result === true) {
            successToast("Verification OTP sent to your phone.");
          } else {
            errorToast("Failed to send OTP.");
          }
          setSignInModal(true);
        } catch (otpError) {
          console.error("Failed to send OTP:", otpError);
          errorToast("Failed to send OTP.");
        }
      } else {
        navigate("/Thankyou");
      }
    } catch (error) {
      console.error(error);
      setloading(false);

      const errorMessage = error?.response?.data?.message;

      if (errorMessage === "User is not verified ") {
        try {
          // Call the sendOTPForVerify API if the user is not verified
          let { data: otpResponse } = await getsendOTPForVerify({
            phone: mobile,
          });
          if (otpResponse.result === true) {
            successToast("Verification OTP sent to your phone.");
          } else {
            errorToast("Failed to send OTP.");
          }
          setSignInModal(true);
        } catch (otpError) {
          console.error("Failed to send OTP:", otpError);
          errorToast("Failed to send OTP.");
        }
      } else {
        errorToast(errorMessage || "Registration failed.");
      }
    }
  };

  console.log(errors, "errors",);

  const onSubmit = async (data) => {
    if (!termsAccepted) {
      errorToast(
        "Please accept our terms and conditions and privacy policy before registering!"
      );
      return;
    }
    console.log(data, "data",category);
    if (category.length == 0) {
      errorToast(
        "Please select at least one category!"
      );
      return;
    }

    const obj = {
      name: data.name,
      email: data.email,
      phone: data.mobile,
      address: data.address,
      brandNames: data.brandNames,
      whatsapp: data.whatsapp,
      dob: data.aniversaryDate,
      role: data.type,
      gstNumber: data.gstNumber,
      countryId: data.countryId,
      stateId: data.stateId,
      cityId: data.cityId,
      aniversaryDate: data.aniversaryDate,
      SourceName: "WEB",
      approved: true,
      categoryArr: category.map((el) => ({ categoryId: el._id })),
      companyObj: {
        name: data.companyName,
        email: data.email,
        phone: data.mobile,
        address: data.address,
        gstNumber: data.gstNumber,
        yearOfEstablishment: data.yearOfEstablishment,
      },
      gstCertificate,
      bannerImage: data.bannerImage,
      profileImage: data.profileImage,
    };
    setRgisterObj(obj);
    await handleRegister(obj);
  };

  const handleNestedCategory = async () => {
    try {
      const { data: res } = await getAllCategories("level=1");
      if (res.success && res.data) {
        setcategoryArr(res.data);
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };
  useEffect(() => {
    handleNestedCategory();
  }, []);

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Product Categories interested in</strong> You can select multiple
      categories if you want.
    </Tooltip>
  );

  const makeClientCrop = async (crop) => {
    console.log(crop, "crop");
    if (profileImage && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(profileImage, crop);
      console.log(croppedImageUrl, "croppedImageUrl");
      setProfileImage(croppedImageUrl);
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "#bf9f65", // Change placeholder color
      fontSize: ".9rem",
      fontWeight: "500",
      fontFamily: "Poppins, sans serif",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      borderColor: "#ccc",
      height: 50,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007aff" : "white",
      color: state.isSelected ? "white" : "black",
      padding: 10,
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexWrap: "nowrap", // Prevents wrapping
      overflowX: "auto", // Allows horizontal scrolling if values exceed width
      whiteSpace: "nowrap",
    }),
  };

  const handleCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(profileImage, croppedAreaPixels);
    // setProfileImage(croppedImage);
  };

  return (
    <>
      <div className="regsiter_user topup-subscription-container ">
        <div className="container">
          <div className="row m-3 pt-3">
            <div className="col-12 col-md-12">
              <div className="right">
                <h3 className="heading yellow">Register</h3>

                <form className="form row" onSubmit={handleSubmit(onSubmit)}>
                  {/* <div className="col-md-12">
                                <label>Select Vendor Type? <span className="text-danger">*</span></label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                    <div >
                                        {[
                                        ROLES_CONSTANT.CORPORATE,
                                        ROLES_CONSTANT.MANUFACTURER,
                                        ROLES_CONSTANT.DISTRIBUTOR,
                                        ROLES_CONSTANT.DEALER,
                                        ROLES_CONSTANT.RETAILER,
                                        ROLES_CONSTANT.CONTRACTOR,
                                        ].map((role) => (

                                          <><input type="radio" {...field} value={role} checked={field.value === role} /><b className="mx-2">{role === ROLES_CONSTANT.CORPORATE ? "MANUFACTURER (CORPORATE)" : role}</b><br className="mobile-header" /></>
                                        ))}
                                    </div>
                                    )}
                                />
                                {errors.type && (
                                    <div className="invalid-feedback d-block">{errors.type.message}</div>
                                )}
                                </div> */}

                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        Select Vendor Type ?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control"
                          value={type}
                          closeMenuOnSelect={false}
                          onChange={(e) => settype(e.target.value)}
                        //   style={{ appearance: "none" }}
                        >
                          <option value="">-- Select Vendor Type --</option>
                          <option value={ROLES_CONSTANT.CORPORATE}>
                            MANUFACTURER (CORPORATE)
                          </option>
                          <option value={ROLES_CONSTANT.MANUFACTURER}>
                            {ROLES_CONSTANT.MANUFACTURER}
                          </option>
                          <option value={ROLES_CONSTANT.DISTRIBUTOR}>
                            DISTRIBUTOR
                          </option>
                          <option value={ROLES_CONSTANT.DEALER}>DEALER</option>
                          <option value={ROLES_CONSTANT.RETAILER}>
                            RETAILER
                          </option>
                          <option value={ROLES_CONSTANT.CONTRACTOR}>
                            CONTRACTOR
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>
                        Business Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.companyName ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Business Name"
                          />
                        )}
                      />
                      {errors.companyName && (
                        <div className="invalid-feedback">
                          {errors.companyName.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Year of Establishment{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="yearOfEstablishment"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="number"
                            className={`form-control ${
                              errors.yearOfEstablishment ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Year of Establishment"
                            maxLength={4}
                            minLength={4}
                          />
                        )}
                      />
                      {errors.yearOfEstablishment && (
                        <div className="invalid-feedback">
                          {errors.yearOfEstablishment.message}
                        </div>
                      )}
                    </div>

                    {/* <div className="col-md-6" >
                                <OverlayTrigger placement="right" overlay={tooltip}>
                                    <label>
                                        Category<span className="text-danger me-2">*</span>
                                        <AiOutlineInfoCircle />
                                    </label>
                                </OverlayTrigger>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className={`form-control bg-transperant ${errors.category ? "is-invalid" : ""}`}
                                            style={{ height: 'auto' }}
                                            options={categoryArr.map((el) => ({
                                                label: el.name,
                                                value: el._id,
                                            }))}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    minHeight: '40px',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    flexWrap: 'wrap',
                                                }),
                                            }}
                                        />
                                    )}
                                />
                                {errors.category && (
                                    <div className="invalid-feedback">{errors.category.message}</div>
                                )}
                                </div> */}
                    <div className="col-md-6">
                      <div style={{ width: "max-content" }}>
                        <OverlayTrigger placement="right" overlay={tooltip}>
                          <label>
                            Category<span className="text-danger me-2">*</span>
                            <AiOutlineInfoCircle />
                          </label>
                        </OverlayTrigger>
                      </div>
                      <Select
                        styles={customStyles}
                        placeholder="Please Select Category"
                        options={
                          categoryArr &&
                          categoryArr.length > 0 &&
                          categoryArr.map((el) => ({
                            ...el,
                            label: el.name,
                            value: el._id,
                          }))
                        }
                        value={category}
                        closeMenuOnSelect={false}
                        onChange={(e) => setcategory(e)}
                        isMulti
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Dealing With Brand Names{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="brandNames"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.brandNames ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Brand Names"
                          />
                        )}
                      />
                      {errors.brandNames && (
                        <div className="invalid-feedback">
                          {errors.brandNames.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        GST No{" "}
                        {type === ROLES_CONSTANT.CORPORATE && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <Controller
                        name="gstNumber"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.gstNumber ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter GST No"
                          />
                        )}
                      />
                      {errors.gstNumber && (
                        <div className="invalid-feedback">
                          {errors.gstNumber.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Country <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="countryId"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={`form-control ${
                              errors.countryId ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please Select Country</option>
                            {countries?.data.map((country) => (
                              <option key={country._id} value={country._id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.countryId && (
                        <div className="invalid-feedback">
                          {errors.countryId.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        State <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="stateId"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={`form-control ${
                              errors.stateId ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please Select State</option>
                            {states?.data.map((state) => (
                              <option key={state._id} value={state._id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.stateId && (
                        <div className="invalid-feedback">
                          {errors.stateId.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        City <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="cityId"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={`form-control ${
                              errors.cityId ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please Select City</option>
                            {cities?.data.map((city) => (
                              <option key={city._id} value={city._id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors.cityId && (
                        <div className="invalid-feedback">
                          {errors.cityId.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12">
                      <label>
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            className={`form-control ${
                              errors.address ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Address"
                            rows={3}
                          />
                        )}
                      />
                      {errors.address && (
                        <div className="invalid-feedback">
                          {errors.address.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Profile Photo{" "}
                        {type === ROLES_CONSTANT.CORPORATE && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <Controller
                        name="profileImage"
                        control={control}
                        render={({ field }) => (
                          <FileInput
                            setFile={async (e) => {
                              const base64 = await convertFileToBase64(e);
                              field.onChange(base64);
                            }}
                            file={field.value}
                            type="image"
                            previousFile={
                              field.value && field.value.includes("base64")
                                ? field.value
                                : null
                            }
                          />
                        )}
                      />
                      {errors.profileImage && (
                        <div className="invalid-feedback">
                          {errors.profileImage.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Banner Photo (Exterior/Interior Image of your
                        Showroom/Unit)
                        {type === ROLES_CONSTANT.CORPORATE && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <Controller
                        name="bannerImage"
                        control={control}
                        render={({ field }) => (
                          <FileInput
                            setFile={async (e) => {
                              const base64 = await convertFileToBase64(e);
                              field.onChange(base64);
                            }}
                            file={field.value}
                            type="image"
                            previousFile={
                              field.value && field.value.includes("base64")
                                ? field.value
                                : null
                            }
                          />
                        )}
                      />
                      {errors.bannerImage && (
                        <div className="invalid-feedback">
                          {errors.bannerImage.message}
                        </div>
                      )}
                    </div>

                    <h4 className="heading yellow mt-4">
                      Contact Person Details
                    </h4>

                    <div className="col-md-6">
                      <label>
                        Name of Authorised person{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter person Name"
                          />
                        )}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">
                          {errors.name.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Date of Birth{" "}
                        {type === ROLES_CONSTANT.CORPORATE && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <Controller
                        name="aniversaryDate"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="date"
                            className={`form-control ${
                              errors.aniversaryDate ? "is-invalid" : ""
                            }`}
                            value={
                              field.value
                                ? moment(field.value).format("YYYY-MM-DD")
                                : ""
                            }
                            onChange={(e) =>
                              field.onChange(new Date(e.target.value))
                            }
                          />
                        )}
                      />
                      {errors.aniversaryDate && (
                        <div className="invalid-feedback">
                          {errors.aniversaryDate.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Your Email Id <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Your Email Id"
                          />
                        )}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {errors.email.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Mobile No. <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="mobile"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="tel"
                            className={`form-control ${
                              errors.mobile ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Mobile No."
                            maxLength="10"
                          />
                        )}
                      />
                      {errors.mobile && (
                        <div className="invalid-feedback">
                          {errors.mobile.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label>
                        Whatsapp No.{" "}
                        {type === ROLES_CONSTANT.CORPORATE && (
                          <span className="text-danger">*</span>
                        )}
                      </label>
                      <Controller
                        name="whatsapp"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="tel"
                            className={`form-control ${
                              errors.whatsapp ? "is-invalid" : ""
                            }`}
                            placeholder="Please Enter Whatsapp No."
                            maxLength="10"
                          />
                        )}
                      />
                      {errors.whatsapp && (
                        <div className="invalid-feedback">
                          {errors.whatsapp.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="mobilebootm">
                        <input
                          type="checkbox"
                          checked={termsAccepted}
                          onChange={(e) => setTermsAccepted(e.target.checked)}
                          className="check me-2"
                        />
                        Please Accept our{" "}
                        <Link to="/Terms">terms and condition</Link> and{" "}
                        <Link to="/Privacy">privacy policy</Link> before
                        registering
                      </div>
                      <button
                        type="submit"
                        className="btn btn-custom btn-yellow my-3"
                        disabled={loading}
                      >
                        {loading ? "Please Wait ..." : "Register"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={signInModal} centered>
        <Modal.Body className="sign-in-modal custom-modal subscription-card-container p-lg-5 p-3">
          <div>
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="" className="main-logo img-fluid" />
            </Link>
          </div>
          <h2 className="heading"> Verfiy Phone</h2>
          <form className="form row">
            <div className="col-12">
              <div className="input flex-1">
                <label className="text-start">Enter OTP sent to {mobile}</label>
                <input
                  type="text"
                  className="w-100 form-control bg-grey"
                  placeholder="Enter Your OTP"
                  value={otp}
                  onChange={(e) => setotp(e.target.value)}
                />

                <div className="t mt-4">
                  <div
                    className="btn btn-custom btn-yellow mt-2 mb-4"
                    onClick={() => {
                      handlesendOtp();
                      setSignInModal(false);
                    }}
                  >
                    Verfiy Phone
                  </div>
                </div>

                <div className=" d-flex justify-content-between">
                  <div
                    className="resendtp"
                    onClick={() => setSignInModal(false)}
                  >
                    Edit phone no
                  </div>

                  <div
                    className="resendtp"
                    onClick={() => {
                      resendOtp();
                    }}
                  >
                    Resend OTP
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{ display: "grid", placeItems: "center", padding: "0px 30px" }}
        >
          <AiFillCheckCircle
            style={{ color: "black", fontSize: 80, alignSelf: "center" }}
          />
          <h2>
            <b>Registered Successfully!</b>
          </h2>
          <p style={{ color: "black", textAlign: "center", marginTop: 20 }}>
            Your profile will be tagged as <b>Verified</b> once our internal
            team verifies it.
          </p>
          <p style={{ color: "black", textAlign: "center" }}>
            You can Log In using your Email ID or Mobile Number
          </p>
          <button
            className="btn btn-custom btn-yellow mt-2 mb-4"
            onClick={() => navigate("/?loginTriggered=true")}
          >
            Login
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showProfileModal} onHide={handleProfileModalClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{ display: "grid", placeItems: "center", padding: "0px 30px" }}
        >
          <Cropper
            image={profileImage}
            crop={croppedProfilePhoto}
            zoom={zoom}
            // aspect={4 / 3} // You can adjust the aspect ratio as needed
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={handleCropComplete}
          />

          <button
            className="btn btn-custom btn-yellow mt-2 mb-4"
            onClick={() => {
              handleProfileModalClose();
              makeClientCrop(croppedProfilePhoto);
            }}
          >
            Save
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
