import moment from "moment";
import React, { useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import FileInput from "../Utility/FileUploadCropper";
import { convertFileToBase64 } from "../Utility/FileConverterToBase64";
import { getDealershipById, updateDealership, Adddealership } from '../../services/AddDealership.service';
import { getUserById } from "../../services/User.service";
import { errorToast, successToast } from "../Utility/Toast";
import { ROLES_CONSTANT } from "../Utility/constant";
import {  useCities, useCountry, useStates } from "../../services/location.service";
import { useCategories } from "../../services/Category.service";
import { reactCustomStyles } from "../../utils/reactSelectStyle";

// Zod schema definition
const dealershipSchema = z.object({
  type: z.string().min(1, "Please select a type"),
  organisationName: z.string().min(1, "Organization name is required"),
  brandNames: z.string().min(1, "Brand name is required"),
  email: z.string().email("Invalid email address").min(1, "Email is required"),
  countryId: z.string().min(1, "Country is required"),
  stateId: z.string().min(1, "State is required"),
  cityId: z.array(z.object({ value: z.string(), label: z.string() })).min(1, "At least one city is required"),
  category: z.array(z.object({ value: z.string(), label: z.string() })).min(1, "At least one category is required"),
  description: z.string().min(1, "Description is required"),
  profileImage: z.string().min(1, "Image is required"),
});

const AddDealership = () => {
  const navigate = useNavigate();
  const { data: categories } = useCategories({ level: 1 });
  const { data: countries } = useCountry();
  const userObj = useSelector((state) => state.auth.user);
  const role = useSelector((state) => state.auth.role);
  const [searchParams] = useSearchParams();
  const [dealershipId, setDealershipId] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dealerObj, setDealerObj] = React.useState(null);

  const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: zodResolver(dealershipSchema),
    defaultValues: {
      type: ROLES_CONSTANT.MANUFACTURER,
      organisationName: "",
      brandNames: "",
      email: "",
      countryId: "",
      stateId: "",
      cityId: [],
      category: [],
      description: "",
      profileImage: "",
    },
  });

  const countryId = useWatch({ control, name: "countryId" });
  const stateId = useWatch({ control, name: "stateId" });
  const { data: states } = useStates({ countryId }, false, !!countryId);
  const { data: cities } = useCities({ stateId }, false, !!stateId);

  useEffect(() => {
    setDealershipId(searchParams.get('id'));
  }, [searchParams]);

  useEffect(() => {
    if (userObj?._id && !dealershipId) {
      handleGetUser(userObj._id);
    }
  }, [userObj]);

  useEffect(() => {
    if (dealershipId) {
      fetchDealershipDetails(dealershipId);
    }
  }, [dealershipId]);

  const handleGetUser = async (id) => {
    try {
      const { data: res } = await getUserById(id);
      if (res.data) {
        console.log(res.data.companyObj?.name )
        setValue("organisationName", res.data.companyObj?.name || "");
        setValue("email", res.data.email || "");
      }
    } catch (err) {
      errorToast(err);
    }
  };

  const fetchDealershipDetails = async (id) => {
    try {
      const { data: dealership } = await getDealershipById(id);
      if (dealership?.data) {
        setDealerObj(dealership.data);
        reset({
          type: dealership.data.Type || ROLES_CONSTANT.MANUFACTURER,
          organisationName: dealership.data.organisationName || "",
          brandNames: dealership.data.Brand || "",
          email: dealership.data.email || "",
          countryId: dealership.data.countryId || "",
          stateId: dealership.data.stateId || "",
          cityId: dealership.data.cityId.map(id => ({
            value: id,
            label: cities?.data?.find(city => city._id === id)?.name || ""
          })),
          category: dealership.data.categoryArr.map(id => ({
            value: id,
            label: categories?.data?.find(cat => cat._id === id)?.name || ""
          })),
          description: dealership.data.description || "",
          profileImage: dealership.data.image || "",
        });
      }
    } catch (error) {
      errorToast(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = {
        userId: userObj._id,
        Organisation_name: data.organisationName,
        Type: data.type,
        Brand: data.brandNames,
        productId: "", // Assuming this is optional or handled elsewhere
        categoryArr: data.category.map(el => el.value),
        email: data.email,
        companyName: "", // Assuming this is optional
        image: data.profileImage,
        cityId: data.cityId.map(city => city.value),
        stateId: data.stateId,
        description: data.description,
        countryId: data.countryId,
      };

      if (dealershipId) {
        await updateDealership(formData, dealershipId);
        successToast("Dealership updated successfully");
      } else {
        await Adddealership(formData);
        successToast("Dealership added successfully");
      }
      navigate('/mydealerships');
    } catch (error) {
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "#bf9f65", // Change placeholder color
      fontSize: ".9rem",
      fontWeight: "500",
      fontFamily: "Poppins, sans serif",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      borderColor: "#ccc",
      height: 50,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007aff" : "white",
      color: state.isSelected ? "white" : "black",
      padding: 10,
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexWrap: "nowrap", // Prevents wrapping
      overflowX: "auto", // Allows horizontal scrolling if values exceed width
      whiteSpace: "nowrap",
    }),
  };


  return (
    <div className="register_user topup-subscription-container">
      <div className="container">
        <div className="row m-3 pt-3">
          <div className="col-12 col-md-12">
            <div className="right">
              <h3 className="heading yellow">
                {dealershipId ? 'Edit Franchise' : 'Add Franchise'}
              </h3>
              <form className="form row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-6">
                  <label>Select Type <span className="text-danger">*</span></label>
                  {role && (
                    <>
                      {role === ROLES_CONSTANT.MANUFACTURER && (
                        <>
                          <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                              <>
                                <input type="radio" {...field} value={ROLES_CONSTANT.MANUFACTURER} checked={field.value === ROLES_CONSTANT.MANUFACTURER} /> <b className="mx-2">MANUFACTURER</b>
                                <input type="radio" {...field} value={ROLES_CONSTANT.DISTRIBUTOR} checked={field.value === ROLES_CONSTANT.DISTRIBUTOR} /> <b className="mx-2">DISTRIBUTOR</b>
                                <input type="radio" {...field} value={ROLES_CONSTANT.DEALER} checked={field.value === ROLES_CONSTANT.DEALER} /> <b className="mx-2">DEALER</b>
                              </>
                            )}
                          />
                        </>
                      )}
                      {role === ROLES_CONSTANT.DISTRIBUTOR && (
                        <Controller
                          name="type"
                          control={control}
                          render={({ field }) => (
                            <><input type="radio" {...field} value={ROLES_CONSTANT.DEALER} checked={field.value === ROLES_CONSTANT.DEALER} /> <b className="mx-2">DEALER</b></>
                          )}
                        />
                      )}
                    </>
                  )}
                  {errors.type && <span className="text-danger">{errors.type.message}</span>}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label>Name of Organization <span className="text-danger">*</span></label>
                    <Controller
                      name="organisationName"
                      control={control}
                      render={({ field }) => (
                        <input {...field} type="text" className="form-control" placeholder="Please Enter Your Organization's Name" />
                      )}
                    />
                    {errors.organisationName && <span className="text-danger">{errors.organisationName.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>Brand <span className="text-danger">*</span></label>
                    <Controller
                      name="brandNames"
                      control={control}
                      render={({ field }) => (
                        <input {...field} type="text" className="form-control" placeholder="Please Enter Your Brand Name" />
                      )}
                    />
                    {errors.brandNames && <span className="text-danger">{errors.brandNames.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>Category <span className="text-danger">*</span></label>
                    <Controller
                      name="category"
                      control={control}
                      render={({ field }) => (
                        <Select
                         styles={reactCustomStyles}
                         placeholder="Please Select Category"
                          options={categories?.data?.map(cat => ({ value: cat._id, label: cat.name }))}
                          value={field.value}
                          onChange={(selected) => field.onChange(selected)}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      )}
                    />
                    {errors.category && <span className="text-danger">{errors.category.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>Your Email Id <span className="text-danger">*</span></label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input {...field} type="email" className="form-control" placeholder="Please Enter Your Email" />
                      )}
                    />
                    {errors.email && <span className="text-danger">{errors.email.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>Country <span className="text-danger">*</span></label>
                    <Controller
                      name="countryId"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("stateId", "");
                            setValue("cityId", []);
                          }}
                        >
                          <option value="">Select Country</option>
                          {countries?.data?.map((country) => (
                            <option key={country._id} value={country._id}>{country.name}</option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.countryId && <span className="text-danger">{errors.countryId.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>State <span className="text-danger">*</span></label>
                    <Controller
                      name="stateId"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="form-control"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("cityId", []);
                          }}
                        >
                          <option value="">Select State</option>
                          {states?.data?.map((state) => (
                            <option key={state._id} value={state._id}>{state.name}</option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.stateId && <span className="text-danger">{errors.stateId.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>City <span className="text-danger">*</span></label>
                    <Controller
                      name="cityId"
                      control={control}
                      render={({ field }) => (
                        <Select
                        styles={reactCustomStyles}
                        placeholder="Please Select City"
                          options={cities?.data?.map(city => ({ value: city._id, label: city.name }))}
                          value={field.value}
                          onChange={(selected) => field.onChange(selected)}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      )}
                    />
                    {errors.cityId && <span className="text-danger">{errors.cityId.message}</span>}
                  </div>
                  {/* <div className="col-md-6">
                      <div style={{ width: "max-content" }}>
                        <OverlayTrigger placement="right" overlay={tooltip}>
                          <label>
                            City<span className="text-danger me-2">*</span>
                            <AiOutlineInfoCircle />
                          </label>
                        </OverlayTrigger>
                      </div>
                      <Select
                        styles={customStyles}
                        placeholder="Please Select City"
                        options={
                          cities?.data &&
                          cities?.data.length > 0 &&
                          cities?.data.map((city) => ({
                            label: city.name,
                            value: city._id,
                          }))
                        }
                        value={cityId}
                        closeMenuOnSelect={false}
                        onChange={(selectedOptions) =>
                          setCityId(selectedOptions)
                        }
                        isMulti
                      />
                      {errors.cityId && <span className="text-danger">{errors.cityId.message}</span>}
                    </div> */}

                  <div className="col-md-6">
                    <label>Description <span className="text-danger">*</span></label>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <input {...field} type="text" className="form-control" placeholder="Enter your Description" />
                      )}
                    />
                    {errors.description && <span className="text-danger">{errors.description.message}</span>}
                  </div>

                  <div className="col-md-6">
                    <label>Image <span className="text-danger">*</span></label>
                    <Controller
                      name="profileImage"
                      control={control}
                      render={({ field }) => (
                        <FileInput
                          setFile={async (e) => {
                            const base64 = await convertFileToBase64(e);
                            setValue("profileImage", base64);
                          }}
                          file={field.value}
                          type="image"
                          previousFile={field.value && field.value.includes("base64") ? field.value : null}
                        />
                      )}
                    />
                    {errors.profileImage && <span className="text-danger">{errors.profileImage.message}</span>}
                  </div>

                  <div className="col-12 mt-3 mb-3">
                    <button type="submit" className="btn btn-custom btn-yellow mt-5" disabled={loading}>
                      {loading ? "Please Wait..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} className="">
        <Modal.Header closeButton className="subscription-card-container">
          <Modal.Title>Submission Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body className="subscription-card-container">
          Your dealership information has been submitted successfully.
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddDealership;